.timer_wrap {
	margin: 24px 0;

	.timer {
		display: flex;
		display: -webkit-flex;
		justify-content: center;

		.countdown {
			margin: 0 10px;
			font-size: inherit;
			font-weight: bold;
		}
	}
}

@media screen and (min-width: 768px) {
	.timer_wrap {
		margin: 40px 0;
	}
}
