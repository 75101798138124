
/* --------------------------------
		Radio Button
-------------------------------- */


.radioContainer {
    margin: 20px 0;
    display: block;
    position: relative;
    padding-left: 30px;
    // margin-bottom: 8px;
    cursor: pointer;
    font-size: 18px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;
    color: #333333;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        /*background-color: #009000;*/
        border: 1px solid #007C92;
      }
      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid #ccc;
      &:after {
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        border-radius: 50%;
        background: #007C92;
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  .form-gdpr{
    .gdpr_remember_me{
      display: block;
      width: 245px;
      margin-left: 30px;
      font-size: 14px;
      color: #666666;
      text-align: left;
      line-height: 18px;
    }
    .remember-wrapper{
      margin-top: 0px !important;
      padding-top: 0px;
    }
    .radioStylesCenterAlign{
      display: block;
      margin-bottom:34px;
      font-size:18px;
      line-height:24px;
      text-align:center;
      color:#333333
    }
  }