/* --------------------------------
		Screen
-------------------------------- */

$screenWidthMobile: 328px;
$screenWidthTab: 640px;
$screenWidthTab_768: 768px;
$screenWidthDesktop: 1024px;
$formPanelWidthDesktop: 350px;
$formPanelWidthDevice: 280px;

/* --------------------------------
		Colors
-------------------------------- */

$vodafoneTransparentWhite: rgba(255, 255, 255, 0.95);
$vodafoneTransparentBlack: rgba(0, 0, 0, 0.5);
$vodafoneTransparentDark: rgba(0, 0, 0, 0.85);
$vodafoneRed: #e60000;
$vodafoneDarkRed: #990000;
$vodafoneLightGreen: #00ca4d;
$vodafoneGreen: #428600;
$vodafoneOrange: #eb9700;
$vodafoneDarkerGrey: #333333;
$vodafoneDarkGrey: #666666;
$vodafoneGrey: #cccccc;
$vodafoneLighterGrey: #f4f4f4;
$vodafoneLighterGrey: #fbfbfb;
$vodafonePurple: #9c2aa0;
$vodafoneYellow: #fecb00;
$vodafoneWhite: #ffffff;
$vodafoneLightGrey: #999999;
$vodafoneOverlayTitleColor: #4c4c4c;
$vodafoneErrorMessageColor: #ff0000;
$vodafoneSuccessMessageColor: #009900;
$vodafoneWarningMessageColor: #eb9700;
$navbarColor: rgba(0, 0, 0, 0.16);
$bodyBgColor: #eaeaea;
$vodafoneBlack: #000000;

/* --------------------------------
		Fonts
-------------------------------- */

$primaryFontFamily: "VodafoneRg-Regular", Arial, sans-serif;
$secondaryFontFamily: $primaryFontFamily;
$primaryFontFamilyLT: "VodafoneLt-Regular", Arial, sans-serif;
$primaryFontFamilyBold: "VodafoneRg-Bold", Arial, sans-serif;
$primaryFontSize: 20px;
$primaryFontSizeDesktop:20px;
$primaryFontSizeMobile:18px;
$primaryLabelSizeDesktop:18px;
$primaryLabelSizeMobile:16px;
$titleFontSizeMobile: 18px;
$titleFontSizeDesktop: 18px;
$fontWeightSize: 400;
$primaryFontColor: $vodafoneDarkerGrey;
$titleLineHeightDesktop: 24px;
$titleLineHeightMobile: 24px;
$titleLineHeighttab: 40px;

/* --------------------------------
		Text
-------------------------------- */

$textWidth: 328px;
$textWidthTab: 350px;

$textMargin: 16px auto 32px;

/* --------------------------------
		Common
-------------------------------- */

$opco_list: (
  gb,
  de,
  it,
  es,
  ie,
  is,
  gr,
  nl,
  pt,
  al,
  za,
  at,
  bg,
  hr,
  nz,
  qa,
  gh,
  eg,
  mt,
  cz,
  ro,
  hu,
  au,
  tr,
  in
);

@function uncapitalize($string) {
  @return to-lower-case($string);
}

$defaultBorderRadius: 2px;
$verticalMarginSmall: 10px;

/* --------------------------------
		Logo
-------------------------------- */

$logoWidth: 48px;
$logoHeight: 48px;
$logoWidthMobile: 32px;
$logoHeightMobile: 32px;

/* --------------------------------
		Section
-------------------------------- */

$sectionHorizontalPadding: 20px;
$sectionVerticalMargin: 20px;
$sectionVerticalMarginMobile: 10px;
$sectionVerticalMarginLarge: 40px;
$sectionVerticalMarginLargeMobile: 30px;
$sectionVerticalMarginLargDesktop: 20px;
$sectionHorizontalPaddingTab: 0px;

/* --------------------------------
		Main
-------------------------------- */

$mainWidth: 414px;
$mainWidthDesktop: 350px;

/* --------------------------------
		Label
-------------------------------- */

$labelTextColor: #666666;

/* --------------------------------
		Input
-------------------------------- */

$inputPrefixWidth: 50px;
$inputHeight: 50px;
$inputHeightMobile: 44px;
$inputButtonHeight: 44px;
$inputTextHeightDesktop: 48px;
$inputTextHeightMobile: 48px;
$inputHorizontalPadding: 16px;
$inputBorderColor: #666666;
$inputTextColor: #333333;

$flagWidth: 65px;
$flagHeight: 36px;
$inputWidth: 328px;
$inputWidthMobile: 280px;

/* --------------------------------
		Button
-------------------------------- */

$buttonIconWidth: 32px;
$buttonIconHeight: 32px;

$secondaryButtonBackgroundColor: #666666;

/* --------------------------------
		Dropdown
-------------------------------- */

$dropdownWidth: 105px;
$dropdownWidthMobile: 89px;

/* --------------------------------
		Icon
-------------------------------- */

$iconWidth: 24px;
$iconHeight: 24px;

/* --------------------------------
		Link icon
-------------------------------- */

$linkIconWidth: 64px;
$linkIconHeight: 64px;
$linkIconMargin: 5px;

/* --------------------------------
		Footer
-------------------------------- */

$footerHeight: 80px;
$footerHeightMobile: 116px;

/* --------------------------------
		Radio
-------------------------------- */

$radioLabelTextColor: $vodafoneDarkGrey;