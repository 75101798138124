 @import "../../assets/scss/base";

/* --------------------------------
		Error View
-------------------------------- */

.input_message_wrap {

	margin-bottom: 32px;
	width: 328px;
	max-width: 328px;
	margin-left: auto;
	margin-right: auto;

	.input_message_icon_wrap {

		.input_message_icon {
			width: 100%;
			height: 100%;
			background-size: 24px 24px;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			border-right: none;
			@include border-radius (6px 6px 0 0);
			height: 36px;
			display: table;
			border-radius: 6px 6px 0 0;
			box-sizing: border-box;
			
			&.icon_error, &.icon_warning, &.icon_undefined{
				background-image: url('../../assets/img/alert_level.svg');
				background-color: #E60000;
				border: 1px solid #E60000;
			}
			&.icon_info{
				background-image: $iconInfo;
				background-color: #009900;
				border: 1px solid #009900;
			}
			
			&.icon_info_light {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTIpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMzJ2MzJIMHoiLz48cGF0aCBkPSJNMjQuOTU3IDcuMDQzYzQuOTQ2IDQuOTQ3IDQuOTQ2IDEyLjk2NyAwIDE3LjkxNC00Ljk0NyA0Ljk0Ni0xMi45NjcgNC45NDYtMTcuOTE0IDAtNC45NDYtNC45NDctNC45NDYtMTIuOTY3IDAtMTcuOTE0IDQuOTQ3LTQuOTQ2IDEyLjk2Ny00Ljk0NiAxNy45MTQgMCIgc3Ryb2tlPSIjRkZGIiBmaWxsPSIjRkZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTUuOTAyIDkuOTMxYS4zMzMuMzMzIDAgMTEtLjQ3MS40NzEuMzMzLjMzMyAwIDAxLjQ3MS0uNDcxIiBzdHJva2U9IiNGRkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0xNS43ODUgMTAuMjg1YS4xNjcuMTY3IDAgMTEtLjIzNi0uMjM2LjE2Ny4xNjcgMCAwMS4yMzYuMjM2eiIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBzdHJva2U9IiNFQjk3MDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE2IDIxLjc1VjE0aC0xLjMzM00xNC42NjcgMjJoMi42NjYiLz48Y2lyY2xlIGZpbGw9IiNFQjk3MDAiIGN4PSIxNS42NjciIGN5PSIxMC4xNzMiIHI9IjEiLz48L2c+PC9zdmc+");
				background-color: #eb9700;
				border: 1px solid #eb9700;
			}
		}
	}

	.input_message {
		font-family: VodafoneRg-Regular;
		font-size: 16px;
		color: #333333;
		text-align: left;
		line-height: 22px;
		text-align: left;
		padding: 16px 20px;
		border-radius: 0 0 6px 6px;
		-webkit-flex: 1;
		flex: 1;
		box-sizing: border-box;
		min-height: inherit;
    	min-width: inherit;
    	word-break: break-word;
 		max-width: inherit;
    	overflow: hidden;
		overflow-wrap: break-word;

		&.message_error, &.message_info, &.message_warning, &.message_undefined{
			border: 1px solid #E60000;
		}

		&.message_info{
			border: 1px solid #009900;
		}

		&.message_info_light {
			border: 1px solid #eb9700;
		}

		a {
			text-decoration: underline;
			color: #333333;
		}
	}

	& + .section-form {
		.box {
			.user-name {
				margin-top: -16px;
			}
		}
	}
}

.input_error{
	.input-wrapper{
		.input-border-box{
			border-color:#E60000;
			.box-title {
				color: #E60000;
			}
			.box-contents{
				.show, .hide{
					visibility:hidden;
				}
				.input-error-icon{
					background-image:url('../../assets/img/alert_level.svg');
					background-size: 24px 24px;
    				background-repeat: no-repeat;
    				background-position: left;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.input_message_wrap {
		width: $mainWidthDesktop;
		max-width: $mainWidthDesktop;
		.input_message{
			font-size: 18px;
			line-height: 24px;
			padding: 20px 24px;
			width: $mainWidthDesktop;
			max-width: $mainWidthDesktop;
		}
	}

	.form-error{
		width: $mainWidthDesktop;
		max-width: $mainWidthDesktop;
		margin: 40px 0;
	}

	.hr-sect {
		margin: 8px 0px;
		height: 28px;
		font-size: 18px;
		line-height: 28px;
	}
}
