@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins.scss";
@import "../../assets/scss/_images.scss";

.addrSelectionWrap{
	text-align:left;
	display: flex;
    flex-direction: row;
    align-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding:12px 0px;
    border-bottom: 1px solid $vodafoneGrey;

    .addressSection{
		*:first-child{
			font-size: 18px;
			font-weight: bold;
		}

		font-size: 16px;
		font-family: $primaryFontFamily;
		width: 90%;
		word-break: break-work;
    }

	.addrButton{
		display: flex;
	    flex-direction: column;
	    justify-content: center;
	    width: 10%;

        .arrow{
            cursor: pointer;
            text-align: left;
            padding: 0;
            background-color: white;
            background-image: $addrArrow;
            background-size: 32px 32px;
            background-repeat: no-repeat;
            color: $primaryFontColor;
            font-size: 22px;
            font-weight: bold;
            width: 32px;
            height: 32px;
            &:hover {
                background-color: white;
            }
        }

		.section {
				padding: 0;
				margin: 0;

				.button {
					text-align: left;
					padding: 0;
					background-color: white;
					background-image: $addrArrow;
					background-size: 32px 32px;
					background-repeat: no-repeat;
				    color: $primaryFontColor;
				    font-size: 22px;
				    font-weight: bold;
				    width: 32px;
				    height: 32px;
				    &:hover {
						background-color: white;
				    }
				}
			}
	}
}

.section{
    text-align: center;
    margin: 20px 0 10px;
    overflow: visible;
}

.cifSection{
    background-color: #f2f2f2;
    text-align: left;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    .cifSection{
        font-size: 18px;
    }

    .addrSelectionWrap{
        .addressSection{
            *:first-child{
                font-size: 20px;
            }

            font-size: 18px;
        }
    }
  }