@import "../../assets/scss/variables";

.vf-banner-wrapper {
	position: fixed;
	width: 100%;
	left: 0;
	bottom: 0;
	z-index: 9999;

	.vf-banner {
		padding: 16px;
		background-color: rgba(0, 0, 0, 0.8);
		color: white;
		font-size: 18px;
		line-height: 24px;

		.vf-banner-text-wrapper {
			margin-bottom: 24px;
			font-family: $primaryFontFamilyLT;

			&.desktop {
				display: none;
			}

			.link-wrapper {
				display: inline-block;
				margin: 0;

				a {
					color: #fff !important;
				}
			}
		}

		.vf-banner-controls-wrapper {
			display: flex;
			flex-direction: column-reverse;
            gap: 16px;

            .vf-button-wrapper {
                flex: 1;
                width: 100%;
                margin: auto;
            }

			.button {
				margin: 0 !important;

				&.secondary {
					background-color: $vodafoneLightGrey;

					&:hover {
						background-color: #666;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.vf-banner-wrapper {

		.vf-banner {	
			.vf-banner-text-wrapper {
			}
	
			.vf-banner-controls-wrapper {
				display: flex;
				flex-direction: row;
	
				.button {
					margin-bottom: 0 !important;
					
					&:not(:first-child){
						margin-left: 8px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.vf-banner-wrapper {

		.vf-banner {
			display: flex;
			padding: 16px 40px;
	
			.vf-banner-text-wrapper {
				flex: 1;
				margin-bottom: 0;

				&.mobile {
					display: none;
				}
				
				&.desktop {
					display: flex;
				}
			}
	
			.vf-banner-controls-wrapper {
				margin-left: 40px;

				.button {
				}
			}
		}
	}
}
