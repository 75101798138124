@import "./variables";
@import "./images";

@keyframes slideOpen {
	from {bottom: -100%;}
	to {bottom: 0;}
}

@keyframes fadeIn {
	from {background-color: rgba(0, 0, 0, 0);}
	to {background-color: rgba(0, 0, 0, 0.5);}
}

.mva {
	header {
		position: relative !important;
		padding-top: 32px;
		background-image: $tobiBg;
		background-size: cover;
		// background-size: contain;
		// background-color: #b40001;
		background-repeat: no-repeat;

		z-index: 9999 !important;

		.button {
			display: block !important;
		}

		.navbar-vf {
			flex-direction: row;

			position: relative;
			height: auto;
			background-color: transparent !important;
			box-shadow: none !important;
			border: none;

			&::before {
				display: block;
				content: "";
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 50%;
				background-color: white;
				border-radius: 10px 10px 0 0;
				z-index: -1;
			}

			.container-fluid {
				margin: 0 !important;
				padding: 0 !important;

				.logo_wrapper {
					margin: auto;
					
					.logo {
						width: 96px;
						height: 96px;
						background-size: 96px;
					}
			
					.logo_vf {
						background-image: $tobi;
					}
				}
			}
		}
	}

    .main-wrapper {
        margin-top: 0;

        .service-head {
            display: none;
        }

        .section-head,
        .section-form {
            max-width: 480px !important;
            width: 100% !important;
            padding: 0 16px;

			.link-wrapper {
				text-align: right;
				font-size: 14px;
			}

			.section-subtitle {
				text-align: left !important;
			}
        }

        .section-head {
            margin-bottom: 24px !important;

            .section-title {
                text-align: left !important;
                font-family: $primaryFontFamilyBold !important;
                font-size: 16px !important;
                font-weight: bold !important;
            }
        }
    }
	.wrapper-inner {
		height: 100%;
	}

	.input-border-box {
		position: relative;
		// height: 44px;

		&.focused {
			input {
				border-color: #0096AD;
			}
		}

		.box-contents {
			// margin-top: 18px;
		}
	}

	.inline-error-icon {
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzNfMikiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0Ljc5MDMgMTEuNzc2MkMxNS41NzQxIDEzLjE2MjggMTQuOTM3NSAxNC4zNzUgMTMuMzY1MyAxNC4zNzVIMi42MzQ2OUMxLjA2NzE5IDE0LjM3NSAwLjQyNTAwMiAxMy4xNjI4IDEuMjA5NjkgMTEuNzc2Mkw2LjU3NSAxLjkyMDMxQzcuMzU1OTQgMC41NzU5MzcgOC42Mzc1IDAuNTA0Njg3IDkuNDI1OTQgMS45MjAzMUwxNC43OTAzIDExLjc3NjJaIiBmaWxsPSIjRTYwMDAwIiBzdHJva2U9IiNFNjAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNOCA0LjYyNVY5LjkyMTg4IiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjEzMjU4IDExLjYxNzRDOC4yMDU4MSAxMS42OTA3IDguMjA1ODEgMTEuODA5NCA4LjEzMjU4IDExLjg4MjZDOC4wNTkzNiAxMS45NTU4IDcuOTQwNjQgMTEuOTU1OCA3Ljg2NzQyIDExLjg4MjZDNy43OTQxOSAxMS44MDk0IDcuNzk0MTkgMTEuNjkwNyA3Ljg2NzQyIDExLjYxNzRDNy45NDA2NCAxMS41NDQyIDguMDU5MzYgMTEuNTQ0MiA4LjEzMjU4IDExLjYxNzRaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjc1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8zXzIiPgo8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==) !important;
	}

	.button {
		&.primary {
			&.disabled {
				background-color: #f49e9e;
				color: white;
			}
		}
	}

	.btn-wrapper {
		margin: 24px auto;

		.button {
			margin: 0;
		}
	}

	.toast-message-overlay {
		.toast-message {

			&.error {
				.toast-message-title {
					text-align: center;
					// margin-bottom: 0px;
					font-family: $primaryFontFamilyBold !important;
				}

				.toast-message-text {
					text-align: center;
					margin-bottom: 52px;
					padding: 0 24px;
				}
			}
		}
	}

	.footer-wrapper {
		background: #f2f2f2;
		color: #333;
		
		.footer {
			padding: 24px 16px 24px;
			font-size: 14px;

			a {
				color: #333 !important;

				&::after {
					color: #333 !important;
				}
			}
		}
	}

	.recaptcha-footer-text {
		a {
			color: #333;
	
			&:hover,
			&:target {
				color: #333;
			}
		}
	}

    // .recaptcha-footer-text,
    // footer {
    //     background-color: white;
    //     color: #0d0d0d;
    //     border: 0 none;

    //     a {
    //         color: #0d0d0d !important;
    //     }

    //     .footer-copyright {
    //         text-align: left !important;
    //     }
    // }

	// .recaptcha-footer-text {
	// 	text-align: center;
	// 	padding: 0 16px !important;

	// 	small {
	// 		font-family: $primaryFontFamilyLT;
	// 	}
	// }

    // .footer {
    //     padding: 16px !important;

    //     .footer-copyright {
    //         // font-size: 12px;
    //         color: #999;
    //     }
    // }
}
input[type="password"],
input#PASSWORD {
	padding-right: 48px !important;
	appearance: none;
}