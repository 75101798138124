@import "../../assets/scss/variables";

.greyTick {
    background-image: url('../../assets/img/tick_grey.svg');
    background-repeat: no-repeat;
}

.greenTick {
    background-image: url('../../assets/img/tick_green.svg');
    background-repeat: no-repeat;
}

.rulesIcon {
    font-size: 14px;
    line-height: 18px;
    color: $vodafoneDarkerGrey;
    margin-top:4px;
    &>div{
        margin-bottom: 20px;
    }
    &>p{
        background-size: 18px 18px;
        background-position: left;
        padding-left: 26px;
        margin: 4px 0;
    }
    p:last-child{
      margin-bottom: 32px;
    }
}

.otp-info{
    margin-top: -10px;
    margin-bottom: 32px;
}

.resend-link{
    margin-bottom: 32px;
}

.focusLink{
    text-decoration: underline;
    cursor: pointer;
    display: inline;
}