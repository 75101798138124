@import "../../assets/scss/_variables";
@import "../../assets/scss/_mixins.scss";
@import "../../assets/scss/_images.scss";

/* --------------------------------
		Buttons
-------------------------------- */

@keyframes spin {
	from {
		transform: scale(1) rotate(0);
	}

	to {
		transform: scale(1) rotate(360deg);
	}
}

@-webkit-keyframes spin2 {
	from {
		-webkit-transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

.btn-wrapper {
	margin: 16px 0;
}

.no-margin {
	margin: 0 0;
}

#returnButton {
	margin-top: 0px;
}

.button {
	width: 100%;
	height: $inputButtonHeight;
	margin: 0px;
	padding: 0px 16px;
	font-family: $primaryFontFamily;
	font-size: $primaryFontSizeMobile;
	line-height: 24px;
	border: none;
	box-sizing: border-box;
	@include appearance(none);
	cursor: pointer;
	@include transition(background, 0.25s);
	@include border-radius(0px, 0px, 0px, 0px);
	outline: none;
	white-space: nowrap;

	&[disabled="disabled"] {
		background-color: #f1a4a0;
		color: #fff;

		&:hover {
			cursor: not-allowed;
		}
	}

	&.primary {
		position: relative;
		background-color: $vodafoneRed;
		color: white;
		border-radius: 6px;
		margin-top: 16px;

		&:hover,
		&:active,
		&:focus {
			background-color: $vodafoneDarkRed;
			outline: none;
		}

		&>i.spinner {
			display: none;
			background-image: $vodafoneButtonSpinner;
			background-size: 32px 32px;
			display: none;
			position: absolute;
			top: 8px;
			right: 20px;
			width: 32px;
			height: 32px;
			animation: spin 1s infinite linear;
			-webkit-animation: spin 1s infinite linear;
		}

		&.spinner {
			&>i.spinner {
				display: inline-block;
			}
		}

		&.disabled {
			background-color: #f1a4a0;
			color: #fff;
			pointer-events: none;

			&:hover {
				cursor: not-allowed;
			}
		}
	}

	&.secondary {
		background-color: $secondaryButtonBackgroundColor;
		color: white;
		border-radius: 6px;

		&:hover {
			background-color: $vodafoneDarkerGrey;
		}

		&>i.spinner {
			display: none;
			position: absolute;
			top: 5px;
			right: 5px;
		}

		&.spinner {
			&>i.spinner {
				display: inline-block;
			}
		}
	}

	&+.button {
		margin-top: $sectionVerticalMargin;
	}
}

@media screen and (min-width: 768px) {
	.button {
		font-size: $primaryFontSizeDesktop;
		line-height: 28px;
	}
}