@import "../../assets/scss/images";
$non_mva_background_color: #2a2a2a;
$non_mva_text_color: #fff;
$mva_background_color: #fff;
$mva_text_color: black;
.vf-error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: $non_mva_background_color;
    color: $non_mva_text_color;
    font-size: 18px;
    line-height: 24px;
    font-family: "VodafoneLt-Regular", Arial, sans-serif;

    .vf-error {
        // non mva theme icon
        .vf-error-icon {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTkyIiBoZWlnaHQ9IjE5MiI+PGRlZnM+PHN5bWJvbCBpZD0iYSIgZGF0YS1uYW1lPSJXYXJuaW5nX2ljX2hpIiB2aWV3Qm94PSIwIDAgMTkyIDE5MiI+PHBhdGggZGF0YS1uYW1lPSJCb3VuZGluZyBCb3giIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMTkydjE5MkgweiIvPjxnIGRhdGEtbmFtZT0iJmx0O0dydXBwZSZndDsiPjxwYXRoIGRhdGEtbmFtZT0iJmx0O1BmYWQmZ3Q7IiBkPSJNMTY4LjQzIDE0MC4yNmM4LjM2IDE0LjggMS41MiAyNy43NC0xNS4yIDI3Ljc0SDM4Ljc3Yy0xNi43MiAwLTIzLjU3LTEyLjk0LTE1LjItMjcuNzRMODAuOCAzNS4wNWM4LjMzLTE0LjM1IDIyLTE1LjExIDMwLjQxIDBaIiBmaWxsPSIjZmZmIi8+PC9nPjxwYXRoIGQ9Ik0xNTMuMjMgMTY2SDM4Ljc3Yy0xMS40MiAwLTE4LjIzLTYtMTguNzQtMTQuNThDMTkuNTMgMTYxIDI2LjQ4IDE2OCAzOC43NyAxNjhoMTE0LjQ2YzEyLjI5IDAgMTkuMjQtNyAxOC43NC0xNi41OC0uNTEgOC41OC03LjMyIDE0LjU4LTE4Ljc0IDE0LjU4WiIgb3BhY2l0eT0iLjA2Ii8+PHBhdGggZGF0YS1uYW1lPSImbHQ7UGZhZCZndDsiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2MDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjgiIGQ9Ik05NiA2NHY1Ni41Ii8+PGNpcmNsZSBkYXRhLW5hbWU9IiZsdDtQZmFkJmd0OyIgY3g9Ijk2IiBjeT0iMTM5Ljg5IiByPSI2IiBmaWxsPSIjZTYwMDAwIi8+PC9zeW1ib2w+PC9kZWZzPjxnIGRhdGEtbmFtZT0iV2FybmluZ19pYyI+PHVzZSBkYXRhLW5hbWU9Ildhcm5pbmdfaWNfaGkiIHdpZHRoPSIxOTIiIGhlaWdodD0iMTkyIiB4bGluazpocmVmPSIjYSIvPjwvZz48L3N2Zz4=);
            background-size: 72px;
            height: 72px;
            margin: 0 auto 20px;
            width: 72px;
        }

        .vf-error-title {
            margin-bottom: 16px;
            font-size: 2rem;
            font-weight: 700;
            line-height: 28px;
            margin-bottom: 20px;
        }

        .vf-error-text {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            padding: 0 16px;
        }
    }
}

.vf-error-wrapper.mva {
    background-color: $mva_background_color;
    color: $mva_text_color;
    // mva theme icon
    .vf-error-icon {
        width: 94px;
        height: 94px;
        margin: 0 auto 16px;
        background-image: $warningOneApp;
        background-size: 94px;
    }
    .vf-error-title {
        margin-bottom: 16px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 20px;
    }
}
