@import "../../assets/scss/variables";

.vf-text-wrapper {
    text-align: center;
    max-width: $textWidth;
    font-family: $primaryFontFamilyLT;
    margin: $textMargin;

    &.left {
        .vf-text {
            text-align: left;
        }
    }

    &.center {
        .vf-text {
            text-align: center;
        }
    }

    &.right {
        .vf-text {
            text-align: right;
        }
    }

    &.disabled {
        color: $vodafoneLightGrey;
    }
}

@media screen and (min-width : 768px) {
    .vf-text-wrapper {
        max-width: $textWidthTab;
    }
}

@media screen and (min-width : 1024px) {
}