@import '../../assets/scss/variables';
/* --------------------------------
		Separator
-------------------------------- */

.or_separator_wrap {
	text-align: center;
	margin: $sectionVerticalMargin 0px;
	color: $vodafoneGrey;

	.or_separator_label {
		padding: 0 5px;
        background-color: white;
        font-size: 16px;
	}

	.or_separator {
		height: 1px;
		margin-top: -12px;
		background-color: $vodafoneGrey;
	}
}

@media screen and (min-width: 768px) {
    .or_separator_wrap {
    .or_separator_label{
        font-size: 18px;
    }
    }
}