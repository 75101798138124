@keyframes slideOpen {
	from {top: 40px; bottom: 40px;}
	to {top: 20px; bottom: 20px;}
}

@keyframes fadeIn {
	from {background-color: rgba(0, 0, 0, 0);}
	to {background-color: rgba(0, 0, 0, 0.5);}
}

@media screen and (min-width : 768px) {
	@keyframes slideOpen {
		from {top: 70px; bottom: 70px;}
		to {top: 50px; bottom: 50px;}
	}
	
	@keyframes fadeIn {
		from {background-color: rgba(0, 0, 0, 0);}
		to {background-color: rgba(0, 0, 0, 0.5);}
	}
}

@media screen and (min-width : 1024px) {
}