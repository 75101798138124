@import "../../assets/scss/variables";

.vf-toggle-wrapper {
    .vf-toggle {
        cursor: pointer;

        &.on {
            .vf-toggle-label {
                color: $vodafoneGreen;
                
                &::before {
                    background-color: $vodafoneLightGreen;
                }
    
                &::after {
                    left: 16px;
                }
            }
        }

        .vf-toggle-label {
            position: relative;
            padding-left: 48px;

            transition: all .1s;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -1px;
                left: 0;
                width: 38px;
                height: 26px;
                background-color: $vodafoneGrey;
                border-radius: 25px ;

                transition: all .1s;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                left: 4px;
                width: 18px;
                height: 18px;
                background-color: white;
                border-radius: 25px ;

                transition: all .1s;
            }
        }
    }
}