@import "../../assets/scss/images";
@import '../../assets/scss/variables';

.flag-select {
    img {
      width: $iconWidth;
      height: $iconHeight;
      // position: relative;
    }
    .selected-flag {
      padding-left: 16px;
      font-size: 20px;
      height: 48px;
      background: #ffffff;
      display: flex;
      display: -ms-flexbox;
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
      justify-content: space-between;
      align-items: center;
      border: 1px solid #666666;
      border-radius: 6px;
    }
    .country-flag {
      display: flex;
      display: -ms-flexbox;
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
      align-items: center;

      .flag-prefix{
        font-size: 18px;
        line-height: 24px;
        padding-left: 8px;
        color: #333333;
        align-items: center;
      }
    }
  }

  .flag_sprite {
    background: url("../../assets/img/market_flags.png") no-repeat top left;
  }
  .flag_wrapper {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px !important;
    height: 18px !important;

    position: static;
    display: inline-block;

    &.al_flag_65x34 {
      background-position: 0 0;
    }
    &.at_flag_65x34 {
      background-position: 0 4%;
    }
    &.au_flag_65x34 {
      background-position: 0 8%;
    }
    &.be_flag_65x34 {
      background-position: 0 12%;
    }
    &.bg_flag_65x34 {
      background-position: 0 16%;
    }
    &.cz_flag_65x34 {
      background-position: 0 20%;
    }
    &.de_flag_65x34 {
      background-position: 0 24%;
    }
    &.eg_flag_65x34 {
      background-position: 0 28%;
    }
    &.es_flag_65x34 {
      background-position: 0 36%;
    }
    &.gb_flag_65x34 {
      background-position: 0 32%;
    }
    &.gh_flag_65x34 {
      background-position: 0 40%;
    }
    &.gr_flag_65x34 {
      background-position: 0 44%;
    }
    &.hr_flag_65x34 {
      background-position: 0 48%;
    }
    &.hu_flag_65x34 {
      background-position: 0 52%;
    }
    &.ie_flag_65x34 {
      background-position: 0 56%;
    }
    &.in_flag_65x34 {
      background-position: 0 60%;
    }
    &.is_flag_65x34 {
      background-position: 0 64%;
    }
    &.it_flag_65x34 {
      background-position: 0 68%;
    }
    &.mt_flag_65x34 {
      background-position: 0 72%;
    }
    &.nl_flag_65x34 {
      background-position: 0 76%;
    }
    &.nz_flag_65x34 {
      background-position: 0 80%;
    }
    &.pt_flag_65x34 {
      background-position: 0 84%;
    }
    &.qa_flag_65x34 {
      background-position: 0 88%;
    }
    &.ro_flag_65x34 {
      background-position: 0 92%;
    }
    &.tr_flag_65x34 {
      background-position: 0 100%;
    }
    &.za_flag_65x34 {
      background-position: 0 96%;
    }
  }

  @media screen and (min-width: 768px) {
    .flag-select {
      .selected-flag {
        height: 48px;
      }
    }
}