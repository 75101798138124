@import "../../assets/scss/variables";

.link-wrapper{
    font-family: $primaryFontFamily;
    font-size: $primaryLabelSizeMobile;
    color:$vodafoneDarkerGrey;
    text-align: left;
    line-height: 24px;
    margin:16px 0;

    a {
        text-decoration: underline !important;
    }
}

@media screen and (min-width: 768px) {
    .link-wrapper{
        font-size: $primaryLabelSizeDesktop;
    }
}