@import "../../assets/scss/images";
@import "../../assets/scss/variables";

.input-icons {
    .box-contents {
        i {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 12px;
            margin: auto 0;
        }
    }
}

.input-icons {
    width: 100%;
}

.icon {
    padding: 12px;
    // min-width: 36px;
    text-align: center;
    margin-left: -36px;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.show{
    background-image: url('../../assets/img/hide_password.svg');
    // background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 3px;
}

.hide{
    background-image: url('../../assets/img/view_password.svg');
    // background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left;
    margin-top: 3px;
}

.input-field {
    width: 100%;
    text-align: center;
}

.no-bottom-margin{
    margin-bottom: 0 !important;
}

.inline_error{
	.input-wrapper{
		.input-border-box{
			border-color:#E60000;
			.box-title {
				color: #E60000;
			}
        }
        .box-error{
            color: #E60000;
            font-size: 14px;
            line-height: 18px;
            margin: 4px 0;
            .inline-error-icon{
                position: absolute;
                background-image:url('../../assets/img/input_field.svg');
                background-size: 18px 18px;
                background-repeat: no-repeat;
                background-position: left;
                min-width: 18px;
                height: 18px;
            }
            &>span{
                margin-left: 26px;
            }
        }
	}
}

.input-border-box {
	.box-title {
		display: none;
	}

	.box-contents {
        position: relative;
		// margin-top: 22px;

		&.paddingTop20 {
			padding-top: 0 !important;
		}
	}
}

input[type="password"],
input#password {
	padding-right: 48px !important;
	appearance: none;
}

@media screen and (min-width: 768px) {
    .icon {
        margin-left: -40px;
    }

    .input-border-box {
        .box-contents {
            // margin-top: 27px;
        }
    }
}