@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

/* --------------------------------
		Checkbox
-------------------------------- */

.checkbox_wrap {
	position: relative;
}

.checkBoxOuter {
    align-items: center;
    display: flex;
	position: relative;
	padding-left: 28px;
	cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: left;
	font-family: $primaryFontFamily;
	font-size: $primaryLabelSizeMobile;
	color:$vodafoneDarkerGrey;
	text-align: left;
	line-height: 24px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked ~ .checkmark {
		  background-color: #007C92;
		  border: 1px solid #007C92;

		  &:after {
				display: block;
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 3px;
		left: 0;
		height: 20px;
		width: 20px;
        background:$vodafoneWhite;
        border: 1px solid $vodafoneLightGrey;
        border-radius: 3px;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 5px;
            height: 11px;
            border: solid $vodafoneWhite;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
		}
	}
}

.checkbox_label {
	position: relative;
	text-align: left;
	min-height: $iconHeight;
	padding: 0px 0px 0px $iconWidth + $inputHorizontalPadding;
	display: block;
	z-index: 1;

	& ~ label.error {
		margin-top: $verticalMarginSmall;
		border-top: 1px solid $vodafoneDarkRed;
		@include border-radius();

		&:before {
			content: none;
		}
	}
}

@media screen and (min-width: 768px) {
    .checkBoxOuter{
        font-size: $primaryLabelSizeDesktop;
    }
}