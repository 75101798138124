/* --------------------------------
		Header
-------------------------------- */
@import '../../assets/scss/variables';
@import '../../assets/scss/images';

header {
	.button {
		display: none;
		
		appearance: none;
		margin: 0;
		padding: 0;
		background-color: transparent;
		cursor: pointer;
		z-index: 9999;

		&.icon {
			position: absolute;
			top: 28px;
			left: 16px;
			width: 24px;
			height: 24px;
			
			&.backToService {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjI1IDMuNUwyLjc1IDEyTTIuNzUgMTJMMTEuMjUgMjAuNU0yLjc1IDEySDIxLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) !important;
			}
		}
	}

	&.fixed-top {
		position: fixed;
		right: 0;
		left: 0;
		z-index: 1030;
	}

	.navbar-vf {
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		border-bottom: 1px solid #bebebe;
		background: $vodafoneWhite;
		width: 100%;
		padding: 0;
		height: 56px;
	}

	.container-fluid {
		padding-left: 16px !important;
	}

	.logo_wrapper {
		// padding: 8px 0px 8px 11px;
		height: inherit;

		.logo {
			width: $logoWidthMobile;
			height: $logoHeightMobile;
			background-size: $logoWidthMobile $logoHeightMobile;
			background-repeat: no-repeat;
		}

		.logo_vf {
			background-image: $vodafoneLogo;
		}
	}
}

@media screen and (min-width: 768px) {

	header {
		.navbar-vf {
			height: 72px;
		}

		.container-fluid {
			padding-left: 20px;
		}

		.logo_wrapper {
			height: inherit;

			.logo {
				width: $logoWidth;
				height: $logoHeight;
				background-size: $logoWidth $logoHeight;
			}
		}
	}
}