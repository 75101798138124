/* --------------------------------
		Footer
-------------------------------- */
@import '../../assets/scss/variables';
@import '../../assets/scss/images';

.footer-wrapper {
	background-color: $vodafoneBlack;
	color: $vodafoneWhite;

	.footer {
		padding: 18px;

		.footer-nav {
			justify-content: center;
			display: flex;
			flex-wrap: wrap;
			padding-left: 0;
			// margin-bottom: 10px;
			list-style: none;

			li {
				font-size: 14px;
				line-height: 22px;
				margin-bottom: 5px;
				padding-bottom: 8px;
    			padding-top: 8px;

				a {
					color: $vodafoneWhite;
					text-decoration: none;
					white-space: nowrap;

					&.hideLink {
						display: none;
					}
				}

				&:last-child {
					a:after {
						content: "";
						margin-right: 0px;
					}
				}

				&:not(:first-child) {
					a {
						&:before {
							content: "";
							display: inline-block;
							width: 1px;
							height: 20px;
							vertical-align: middle;
							color: $vodafoneWhite;
							margin-left: 18px;
							margin-right: 18px;
							background-color: #999;
						}
					}
				}
			}

			.footer-navbar-text {
				display: inline-block;
			}
		}

		.footer-copyright {
			font-size: 14px;
			text-align: center;
		}
	}
}

@media screen and (min-width: 768px) {
	.footer-wrapper {
		.footer {
			min-height: 72px;
			padding: 18px;
			// display: flex;
			// flex-direction: row;
			// justify-content: space-between;
			align-items: center;

			.footer-nav {
				margin-bottom: 0;

				li {
					margin-bottom: 0;
					font-size: 14px;
					// line-height: 24px;
				}
			}
		}

		.footer-copyright {
			font-size: 14px;
			padding-top: 0px !important;
		}
	}
}