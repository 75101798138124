/* --------------------------------
			User select
-------------------------------- */

@mixin user-select ($value) {
	-webkit-user-select: $value;
	   -moz-user-select: $value;
		-ms-user-select: $value;
			user-select: $value;
}

/* --------------------------------
			Border-radius
-------------------------------- */

@mixin border-radius ($border-top-left-radius: 2px, $border-top-right-radius: 2px, $border-bottom-right-radius: 2px, $border-bottom-left-radius: 2px) {
	-webkit-border-radius: $border-top-left-radius $border-top-right-radius $border-bottom-right-radius $border-bottom-left-radius;
	   -moz-border-radius: $border-top-left-radius $border-top-right-radius $border-bottom-right-radius $border-bottom-left-radius;
			border-radius: $border-top-left-radius $border-top-right-radius $border-bottom-right-radius $border-bottom-left-radius;
}

/* --------------------------------
			Appearance
-------------------------------- */

@mixin appearance ($appearance) {
	-webkit-appearance: $appearance;
	   -moz-appearance: $appearance;
			appearance: $appearance;
}

/* --------------------------------
			Animation
-------------------------------- */

@mixin animation ($name, $duration, $iteration, $ease: ease-in ) {
	-webkit-animation: labelErrorTopBorder $duration $iteration;
   			animation: labelErrorTopBorder $duration $iteration;
   	-webkit-animation-timing-function: $ease;
   			animation-timing-function: $ease;
}

/* --------------------------------
			Spin animation
-------------------------------- */

@mixin spin-animation(){
	-webkit-animation: spin 1s infinite linear;
	   -moz-animation: spin 1s infinite linear;
		-ms-animation: spin 1s infinite linear;
			animation: spin 1s infinite linear;
}

/* --------------------------------
			Transition
-------------------------------- */

@mixin transition ($property, $duration, $ease: ease-in) {
	-webkit-transition-property: $property;
			transition-property: $property;
	-webkit-transition-duration: $duration;
			transition-duration: $duration;
	-webkit-transition-timing-function: $ease;
			transition-timing-function: $ease;
}

/* --------------------------------
			Transform
-------------------------------- */

@mixin transform ($property) {
		-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
				transform: rotate(45deg);
}
