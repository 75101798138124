@import "../../assets/scss/variables";
@import "../../assets/scss/images";

// OTP
$dot: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT5QYXRoPC90aXRsZT4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik02LjgyMjM5NTY1LDEuMTY2MzU0MDggQzUuMjU2NzAzODgsLTAuMzkyNDE5MDcgMi43MjQxNTg2MywtMC4zODYxNTk5NDIgMS4xNjU5NzczNywxLjE3OTEyMjcgQy0wLjM5Mjk1NDk0LDIuNzQ0OTA2MDggLTAuMzg3MTk2ODgsNS4yNzc1OTk0OSAxLjE3ODI0NDU0LDYuODM1ODcxOTIgQzIuNzQ0NDM3MDIsOC4zOTQ2NDUwNyA1LjI3NzQ4Mjk3LDguMzg4ODg2NjcgNi44MzU0MTM4OCw2LjgyMzEwMzMgQzguMzkzNTk1MTQsNS4yNTczMTk5MiA4LjM4ODMzNzc4LDIuNzI0Mzc2MTQgNi44MjIzOTU2NSwxLjE2NjM1NDA4IFoiIGlkPSJQYXRoIiBmaWxsPSIjMDMwMTA0IiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+);

$primaryFontFamilyLT: "VodafoneLt-Regular", Arial, sans-serif;
$vodafoneTurquoise: #007c92;
$inputFontSize: 18px;

$inputOTPLabelMarginBottom: 8px;

$inputOTPPartWidth: 48px;
$inputOTPPartHeight: 48px;
$inputOTPPartBorder: 1px solid #999;
$inputOTPPartBorderRadius: 6px;

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    transition: background-color 10000s ease-in-out 0s;
}

.vf-input-otp-wrapper {

    &.size-4 {
        .vf-input-otp {
            justify-content: flex-start;
        }
    }

    .vf-input-otp-label {
        margin-bottom: $inputOTPLabelMarginBottom;
        // font-family: $primaryFontFamilyLT;
        font-size: 18px;
    }

    .vf-input-otp {
        display: flex;
        justify-content: space-between;

        &.secured {
            .vf-input-otp-part-wrap {
                .vf-input-otp-part {
                    &:not([value=""]) {
                        font-size: 0;

                        & + .dot {
                            display: block;
                        }
                    }
                }
            }
        }

        .vf-input-otp-part-wrap {
            position: relative;

            .vf-input-otp-part {
                text-align: center;
                width: $inputOTPPartWidth;
                height: $inputOTPPartHeight;
                border: $inputOTPPartBorder;
                padding: 0;
                border-radius: $inputOTPPartBorderRadius;
                outline: none;

                &:focus {
                    border-color: $vodafoneTurquoise;
                }

                &:not([value=""]) {
                    font-size: $inputFontSize;
                    // border-color: $vodafoneTurquoise;

                    & + .dot {
                        display: none;
                        
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: $inputOTPPartWidth;
                        height: $inputOTPPartHeight;

                        background-image: $dot;
                        background-position: center;
                        background-repeat: no-repeat;
                        
                        pointer-events: none;
                    }
                }

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
    }
}